//@flow
import * as React from 'react'
import styled from 'styled-components'
import LoginTemplate from '../../templates/LoginTemplate'
import { withTranslation } from 'react-i18next'
import RegisterTemplate from '../../templates/RegisterTemplate'
import ConfirmPhoneModal from '../../molecules/ConfirmPhoneModal'
import { Link, withRouter } from 'react-router-dom'
import { getOS } from '../../../helper/getOs';
import { Toast, Button } from 'sputnik-ui'

import {
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'


type Props = {
  classes: Object,
  dispatch: Function,
  user: ?Object,
  props: Object,
}

type State = {
  email: string,
  name: string,
  surname: string,
  isConfirmed: boolean,
  error: boolean,
  nameError: string,
  surnameError: string,
  emailError: string,
  didRegisterSuccessfully: boolean,
}

const StyledTextField = styled(TextField)`
    @media screen and (max-width: ${p => p.theme.sizes.phone}) {
      .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
    }

    && .MuiInput-underline:hover:before {
      border-bottom: 1px solid #fff;
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid #fff;
    }
   
    .MuiInput-formControl{
      color:#fff;
    }

    .MuiInputLabel-animated{
      color:#fff;

    }

    &&{
      label{
          color:#fff;
        }
    }
   }
`

class RegisterPage extends React.Component<Props, State> {
  state = {
    email: '',
    name: '',
    surname: '',
    phone: '',
    isConfirmed: false,
    error: false,
    nameError: '',
    surnameError: '',
    emailError: '',
    phoneError: '',
    didRegisterSuccessfully: false,
    confirmPhoneModal: false,
    checkNewPassword: false,
    errorMessage: ''
  }

  handleChange = (prop) => (e: SyntheticInputEvent<EventTarget>) => {
    this.setState({
      [prop]: e.target.value,
      error: false,
      [`${prop}Error`]: '',
    })
  }

  onSubmit =  (e: SyntheticEvent<>) => {
    e.preventDefault()
    const { email, name, surname, phone } = this.state

    fetch(`${window.location.origin}/registration/users`, {
      method: 'POST',
      body: JSON.stringify({ name, surname, email, phone, origin_platform: getOS() }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((r) => {
        if (!r.ok) throw r
        return r.json()
      })
      .then(({ redirect_to, confirmation_required }) => {
        if (!confirmation_required) {
          window.location = redirect_to
          // this.props.history.push(`/welcome?redirect_to=${redirect_to || ''}`)
        } else {
          this.toggleConfirmPhoneModal()
        }
      })
      .catch((err) => {
        err.json().then((response) => {
          this.setState({errorMessage: response.message.contact})
          // this.setState({
          //   error: true,
          //   emailError: response.message.email || '',
          //   nameError: response.message.name || '',
          //   surnameError: response.message.surname || '',
          //   phoneError: response.message.phone || '',
          // })
        }
        )
      })
  }

  toggleConfirmPhoneModal = () =>
    this.setState({ confirmPhoneModal: !this.state.confirmPhoneModal })

  render() {
    const { classes, t } = this.props
   console.log(t('common:title.register'))
    return (
      <RegisterTemplate
        onSubmit={this.onSubmit}
        isConfirmed={this.state.isConfirmed}
        altLink={{ to: '/login', label: t('common:button.is_account') }}
        register={t('common:title.register')}
        handleConfirmChange={(e) =>
          this.setState({ isConfirmed: !this.state.isConfirmed })
        }
        form={
          <>
            <StyledTextField
              required
              autoFocus
              autoComplete="name"
              onChange={this.handleChange('name')}
              label={t('common:form.name')}
              error={!!this.state.nameError}
              margin="dense"
              helperText={this.state.nameError}
            />
            <StyledTextField
              required
              autoComplete="surname"
              onChange={this.handleChange('surname')}
              label={t('common:form.surname')}
              error={!!this.state.surnameError}
              margin="dense"
              helperText={this.state.surnameError}
            />

            <StyledTextField
              required
              autoComplete="email"
              onChange={this.handleChange('email')}
              label={t('common:form.email')}
              error={!!this.state.emailError}
              margin="dense"
              helperText={this.state.emailError}
              type="email"
            />
            <StyledTextField
              required
              autoComplete="phone"
              onChange={this.handleChange('phone')}
              label={t('common:form.phone')}
              margin="dense"
              error={!!this.state.phoneError}
              helperText={this.state.phoneError}
            />
            <Button
              disabled={!(this.state.phone && this.state.email && this.state.name && this.state.surname)}
              type='submit'
              primary
              variant='v2'
              onClick={this.onSubmit}
              style={{ width: 320, margin: '48px auto 10px auto' }}
            >
              {t('common:button.register')}
            </Button>
          </>
        }
      >
        <ConfirmPhoneModal
          open={this.state.confirmPhoneModal}
          handleClose={this.toggleConfirmPhoneModal}
          phone={this.state.phone}
          history={this.props.history}
        />

        <Toast
          open={this.state.checkNewPassword}
          handleClose={() => this.setState({ checkNewPassword: false })}
          variant="success"
        >
          {t('common:toast.new_password')}
        </Toast>

        {/* <Toast        
          open={this.state.phoneError || this.state.emailError}
          // handleClose={() => this.setState({ didRegisterSuccessfully: false })}
          variant="info"
          onClick={() => {
            fetch(`https://sso.sputnik.systems/password_resets`, {
              method: 'POST',
              body: JSON.stringify({
                email: this.state.email,
              }),
              headers: { 'Content-Type': 'application/json' },
            })
              .then((r) => r.json())
              .then((data) => {
                this.setState({
                  checkNewPassword: true,
                  phoneError: false,
                  emailError: false,
                })
              })
          }}
        >
          Уже зарегистрированы? Восстановить доступ.
        </Toast> */}
        <Toast
          open={this.state.errorMessage}
          handleClose={() => this.setState({ errorMessage: '' })}
          variant="error"
        >
          {this.state.errorMessage}
        </Toast>
        <Toast
          open={this.state.didRegisterSuccessfully}
          handleClose={() => this.setState({ didRegisterSuccessfully: false })}
          variant="success"
        >
           {t('common:toast.new_password_success')}
        </Toast>
      </RegisterTemplate>
    )
  }
}

export default withRouter(withTranslation()(RegisterPage))
