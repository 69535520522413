// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: linear-gradient(-45deg, #7303c0, #fdeff9, #fffcdc, #fdeff9);
  background-size: 400% 400%;
  /* background: ${p => p.theme.palette.other.pink}; */
`

const Content = styled.section`
  /* padding-top: 1.75rem; */
  margin: 0 auto;
  */min-height: 100%;
  position: relative;
  padding: 2rem;
`

const DocTemplate = ({ children, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      <Content>{children}</Content>
    </Wrapper>
  )
}

DocTemplate.propTypes = {
  children: PropTypes.any.isRequired,
}

export default DocTemplate
