//@flow
import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, MessagePage } from 'sputnik-ui'

function ForbiddenPage({ history }) {
  return (
    <MessagePage
      emote="error"
      title="У вас нет доступа к данному приложению."
      msg="Подключение услуг доступно в личном кабинете партнера."
      action={
        <Button
          primary
          rounded
          style={{ marginTop: '1.625rem' }}
          component="a"
          href="https://partner.sputnik.systems/subscriptions"
        >
          перейти в личный кабинет
        </Button>
      }
    />
  )
}

export default withRouter(ForbiddenPage)
