//@flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoginTemplate from '../../templates/LoginTemplate'
import RegisterTemplate from '../../templates/RegisterTemplate'

import IconButton from '@material-ui/core/IconButton'

import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Button, Loader, Toast, Input } from 'sputnik-ui'
import { Link } from 'react-router-dom'
import { Typography, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'


const StyledInput = styled(Input)`
@media screen and (max-width: ${p => p.theme.sizes.phone}) {
    &&&{
      color: #fff;
      background: inherit;
      p {
        color: #fff;
      }
  }
}
`
const ForgotPasswordButton = styled.button`
  border: none;
  background: none;
  color: black;
  margin-top: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: rgba(92, 92, 92, 1);
  }
`

const RegisterLink = styled(Link)`
  border: none;
  background: none;
  color: black;
  margin-top: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: rgba(92, 92, 92, 1);
  }
  text-align: center;
  text-decoration: none;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 3.625rem; */
  min-width: 17rem;
  max-width: 30rem;
  height: 100%;
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 100%;
  min-height: 22rem;
`
const StyledButton = styled(Button)`
  &&{
    box-shadow: none;
  }
`
type Props = {
  classes: Object,
  dispatch: Function,
  user: ?Object,
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const prettyTimer =(num)=>{
  if(num < 10) return `0${num}`
  return num
}

const delay = 60
let time

function Login(props) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoginValid, setIsLoginValid] = useState(false)
  const [errMessage, setErrMessage] = useState(false)
  const [errMessageValue, setErrMessageValue] = useState('')
  const [seconds, setSeconds] = useState(delay)
  const [send, setSend] = useState(false)
  const { t } = useTranslation('common')


  const detectLogin = () => {
    if (!validateEmail(login)) {
      return `${t('common:toast.code_phone')} ${login}`
    } else {
      return `${t('common:toast.code_email')} ${login}`
    }
  }

  const stopTimer = () => {
    clearTimeout(time)
    setSend(false)
    setSeconds(delay)
   }
  
  useEffect(() => {
    if (seconds > 0 && send) {
      time = setTimeout(() => setSeconds(seconds - 1), 1000)
    } 
    if(seconds <= 0){
      stopTimer()
    }
  }, [seconds, send])


  const onSubmit = async (e) => {
    
    e.preventDefault()
    setSend(true)

    try {
      await fetch(
        `${window.location.origin}/send_otp?login=${login}`,
        {
          method: 'POST',
          mode: 'cors',
          // old mode: 'no-cors',
          credentials: 'same-origin',
        },
      ).then(r => {
        if (r.ok) {
          setIsLoginValid(true)
          return Promise.resolve(r)
        }
        return Promise.reject(r)
      })
    } catch (err) {
      const { message } = await err.json()
      setErrMessage(true)
      setErrMessageValue(message)
    }
  }

  const onSubmitCode = async () => {
    try {
      await fetch(
        `${window.location.origin}/login?login=${login}&password=${password}`,
        {
          method: 'POST',
          mode: 'no-cors',
          credentials: 'same-origin',
        },
      ).then(r => {
        if (!r.ok) return Promise.reject(r)
        return r.json()
      })
      .then(({ redirect_to, confirmation_required }) => {
        window.location = redirect_to
      })
    } catch (err) {
      const { message } = await err.json()
      setError(message)
      setErrMessageValue(message)
      setErrMessage(true)
    }
  }

  const redirectToRegister = () => {
    props.history.push('/register')
  }

  const handlePassword = (value) => {
    if (value.length > 4) {
      setPassword(value.slice(0, 4))
    } else {
      setPassword(value)
    }
  }

  useEffect(() => {
    if (password.length === 4) onSubmitCode()
  }, [password])

  const handleChangeLogin = (value)=>{
    setLogin(value)
    stopTimer()
  }

  return (
    <RegisterTemplate
      title={!isLoginValid ? t('common:title.welcome') : t('common:title.code')}
      onSubmit={onSubmit}
      altLink={isLoginValid && {
        to: '/login',
        label: t('common:button.back'),
        action: () => {
          setIsLoginValid(false)
        }
      }}
      isConfirmed
      withAgreement={false}
      subtitle={!isLoginValid ? t('common:subtitle.welcome') : detectLogin()}
      form={

        !isLoginValid ?
          <>
            <StyledInput
              style={{ width: '100%', maxWidth: '100%' }}
              autoFocus
              autoComplete="email"
              id="login-input"
              value={login}
              onChange={(e) => handleChangeLogin(e.target.value)}
              error={!!error}
              label={t('common:form.login') }
              margin="dense"
              type="text"
            />

            {!!error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', width: 320, margin: '48px auto 10px auto', }}>
              <StyledButton
                primary
                onClick={onSubmit}
                variant="v2"
                style={{ marginBottom: 10 }}
              >
                {t('common:button.enter')}
              </StyledButton>
              <StyledButton
                onClick={redirectToRegister}
                subtle
                style={{ color: '#0EA7FF' }}
              >
                {t('common:button.register')}
              </StyledButton>
            </div>
          </>
          : <>
            <StyledInput
              value={password}
              autoFocus
              onChange={(e) => handlePassword(e.target.value)}
              error={!!error}
              max="9999"
              label={t('common:form.code')}
              type="number"
              style={{ width: '100%', maxWidth: '100%' }}
            />
            <StyledButton
              subtle
              disabled={seconds > 0 && send}
              onClick={onSubmit}
              style={{ width: 320, margin: '48px auto 10px auto', color: '#0EA7FF' }}
            >
              {loading ? <Loader /> : `${t('common:button.resend')} ${seconds > 0 && send ? `0:${prettyTimer(seconds)}` : ''}`}
            </StyledButton>
          </>
      }
    >
      <Toast
        open={errMessage}
        handleClose={() => setErrMessage(false)}
        variant="error"
      >
        {errMessageValue}
      </Toast>
    </RegisterTemplate >
  )

}

export default Login
