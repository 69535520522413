import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import LanguageDetector from 'i18next-browser-languagedetector'
import localesEn from './locales/en/common.json'
import localesRu from './locales/ru/common.json'

const defaultLanguage = 'en'
const langList = ['ru', 'en']
const browserLanguage = (navigator.language || defaultLanguage).split('-')[0]

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(intervalPlural)
  .init({
    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        switch (format) {
          case 'lowercase':
            return value.toLowerCase()
          case 'capitalize':
            return value.charAt(0).toUpperCase() + value.slice(1)
          case 'uppercase':
            return value.toUpperCase()
          case 'date': {
            return new Intl.DateTimeFormat(lng).format(value)
          }
          case 'number': {
            return new Intl.NumberFormat(lng).format(value)
          }
          default:
            return value
        }
      }
    },
    lng: langList.includes(browserLanguage) ? browserLanguage : defaultLanguage,
    defaultNS: 'common',
    load: 'languageOnly',
    resources: {
      ru: {common:localesRu},
      en: {common:localesEn}
    }
  })

export default i18n