import React from 'react'
import styled from 'styled-components'
import stars from './stars.svg'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(38, 249, 211, 0.106) 100%), linear-gradient(184.05deg, rgba(255, 0, 0, 0.2) -8.71%, rgba(255, 0, 0, 0.2) -5.96%, rgba(0, 0, 0, 0) 96.69%), #2A225A;
`

const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${stars});
  background-size: cover;
  background-position: center; 
`


export default function AppTemplate({ children, ...props }) {
  return (
    <Wrapper {...props}>
      <Bg>
        {children}
      </Bg>
    </Wrapper>
  )
}
