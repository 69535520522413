import React from 'react'
import DocTemplate from '../../templates/DocTemplate'

const AgreementPage = () => (
  <DocTemplate>
    <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
    <p>
      Настоящая Политика конфиденциальности персональных данных (далее –
      Политика конфиденциальности) действует в отношении всей информации,
      которую Общество с ограниченной ответственностью “СПУТНИК” (далее ООО
      “СПУТНИК”, Лицензиар) собирает, хранит, использует, передает в совместное
      пользование и защищает в связи и с целью предоставления и разработки
      программных продуктов и приложения, сервиса и веб-сайта “Город.Эксперт”
      (совместно – «Сервис», “Продукты”) в соответствии с данной политикой
      конфиденциальности («Политика конфиденциальности»).
    </p>
    <p>
      Политика конфиденциальности является публичной офертой. Полным и
      безоговорочным принятием условий Политики конфиденциальности является
      совершение Пользователем действий, направленных на использование Сервиса,
      в том числе, регистрация при авторизации в Приложении “Город.Эксперт”.
    </p>
    <p>
      Владельцем исключительных прав на Сервис является ООО «СПУТНИК»,
      осуществляющий предоставление простой неисключительной лицензии на
      использование Сервиса
    </p>
    <p>
      <strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong>
    </p>
    <p>
      В настоящей Политике конфиденциальности используются следующие термины:
    </p>
    <ol>
      <li>
        «<strong>Администрация Сервиса</strong>» – уполномоченные сотрудники по
        управлению Сервисом, действующие от имени Лицензиара, которые организуют
        и (или) осуществляет обработку персональных данных, а также определяют
        цели обработки персональных данных, состав персональных данных,
        подлежащих обработке, действия (операции), совершаемые с персональными
        данными Пользователей Сервиса;
      </li>
      <li>
        «<strong>Персональные данные</strong>» - любая информация, относящаяся к
        прямо или косвенно определенному или определяемому Пользователю Сервиса
        (физическому лицу, субъекту персональных данных);
      </li>
      <li>
        «<strong>Обработка персональных данных</strong>» - любое действие
        (операция) или совокупность действий (операций), совершаемых с
        использованием средств автоматизации или без использования таких средств
        с персональными данными, включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ),
        обезличивание, блокирование, удаление, уничтожение персональных данных;
      </li>
      <li>
        «<strong>Конфиденциальность персональных данных</strong>» - обязательное
        для соблюдения Лицензиаром или иным получившим доступ к персональным
        данным лицом требование не допускать их распространения без согласия
        субъекта персональных данных или наличия иного законного основания;
      </li>
      <li>
        «<strong>Пользователь Сервиса</strong> (далее - Пользователь)» – лицо,
        имеющее доступ к Сервису, посредством сети Интернет и использующее его в
        том числе для получения доступа к Продуктам Лицензиара;
      </li>
      <li>
        «<strong>Cookies</strong>» — небольшой фрагмент данных, отправленный
        веб-сервером и хранимый на устройстве пользователя, который веб-клиент
        или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
        попытке открыть страницу соответствующего сайта;
      </li>
      <li>
        «<strong>IP-адрес</strong>» — уникальный сетевой адрес узла в
        компьютерной сети, построенной по протоколу IP.
      </li>
    </ol>
    <p>
      <strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
    </p>
    <ol>
      <li>
        Использование Пользователем Приложения “Город.Эксперт” или иных
        продуктов Сервиса означает согласие с настоящей Политикой
        конфиденциальности.
      </li>
      <li>
        В случае несогласия с условиями Политики конфиденциальности Пользователь
        должен прекратить такое использование.
      </li>
      <li>
        Настоящая Политика конфиденциальности применяется только к Сервису и
        Продуктам “Город. Эксперт”. Лицензиар не контролирует и не несет
        ответственность за сайты третьих лиц, на которые Пользователь может
        перейти по ссылкам, доступным на Сервисе.
      </li>
      <li>
        Администрация Сервиса не проверяет достоверность персональных данных,
        предоставляемых Пользователем.
      </li>
    </ol>
    <p>
      <strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong>
    </p>
    <ol>
      <li>
        Настоящая Политика конфиденциальности устанавливает обязательства
        Администрации Сервиса по неразглашению и обеспечению режима защиты
        конфиденциальности персональных данных, которые Пользователь
        предоставляет при авторизации на Сервисе, в том числе при получении
        доступа к приложению “Город.Эксперт”.
      </li>
      <li>
        Пользователь Сервиса предоставляет свое согласие Лицензиару на обработку
        своих персональных данных при использовании Сервиса.
      </li>
      <li>
        Данная Политика конфиденциальности применяется ко всем посетителям,
        пользователям и другим лицам, имеющим доступ к Сервису.
      </li>
      <li>
        Создавая учетную запись и используя Сервисы, Пользователь подтверждает,
        что являетесь владельцем предоставляемых данных или уполномочен
        предоставлять эти данные.
      </li>
      <li>
        Обработка персональных данных Пользователя осуществляется в соответствии
        с законодательством Российской Федерации. Персональные данные
        пользователя обрабатываются в целях предоставления Пользователю доступа
        к использованию Сервиса. Лицензиар принимает все необходимые меры для
        защиты персональных данных Пользователя от неправомерного доступа,
        изменения, раскрытия или уничтожения. Лицензиар предоставляет доступ к
        персональным данным Пользователя только тем Пользователям, которым эта
        информация необходима для обеспечения функционирования Сервиса, а также
        иных действий, незапрещённых российским законодательством. Лицензиар
        вправе использовать предоставленную Пользователем информацию, в том
        числе персональные данные, в целях обеспечения соблюдения требований
        действующего законодательства Российской Федерации (в том числе в целях
        предупреждения и/или пресечения незаконных и/или противоправных действий
        Пользователей). Раскрытие предоставленной Пользователем информации может
        быть произведено лишь в соответствии с действующим законодательством
        Российской Федерации по требованию суда, правоохранительных органов, а
        равно в иных предусмотренных законодательством Российской Федерации
        случаях.
      </li>
      <li>
        Персональные данные, разрешённые к обработке в рамках настоящей Политики
        конфиденциальности, включают в себя следующую информацию:
      </li>
      <ol>
        <li>фамилию, имя, отчество Пользователя;</li>
        <li>контактный телефон Пользователя;</li>
        <li>адрес электронной почты (e-mail);</li>
        <li>страну, город проживания Пользователя;</li>
        <li>платежную информацию, если это необходимо.</li>
      </ol>
      <li>
        Также при работе с Сервисом может происходить сбор следующих видов
        информации:
      </li>
      <li>
        Информация соцсетей: если Пользователь заходит в свою учётную запись в
        приложение “Город.Эксперт” через сайты социальных сетей, например
        Facebook или ВКонтакте, он соглашается предоставить Администрации
        Сервиса длительный доступ к своим персональным данным на таких сайтах
        (например, общедоступный профиль, учётные записи, которые есть у
        Пользователя или относятся к нему, дополнительный адрес электронной
        почты);
      </li>
      <li>
        Информация из других источников: информация, которую собирает
        Администрация Сервиса, может сочетаться с информацией из внешних
        источников (например, демографические данные и дополнительная контактная
        информация), которая была получена в соответствии с законом;
      </li>
      <li>
        Дополнительная информация: Администрация Сервиса может собирать
        дополнительную информацию, когда Пользователь получает доступ к
        приложению через определенное устройство (например, уникальный
        идентификатор мобильного устройства, информация об операционной системе
        мобильного устройства, о браузере, языке браузера или операционной
        системы; о беспроводной сети, а также операторе мобильной связи.
        Администрация Сервиса может также собирать данные WPS о местоположении
        Пользователя. Пользователь может отключить предоставление этих данных,
        изменив настройки определения местоположения);
      </li>
      <li>
        Любая иная персональная информация, неоговоренная выше подлежит
        надежному хранению и нераспространению, за исключением случаев,
        предусмотренных в п.п. 5.2. и 5.3. настоящей Политики
        конфиденциальности.
      </li>
    </ol>
    <p>
      <strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong>
    </p>
    <p>
      Персональные данные Пользователя Администрация Сервиса может использовать
      в целях:
    </p>
    <ol>
      <li>Идентификации Пользователя для использования функций Сервиса;</li>
      <li>
        Предоставления Пользователю доступа к персонализированным ресурсам
        Сервиса;
      </li>
      <li>
        Установления с Пользователем обратной связи, включая направление
        уведомлений, запросов, касающихся использования Сервиса, оказания услуг,
        обработка запросов и заявок от Пользователя;
      </li>
      <li>
        Определения места нахождения Пользователя для обеспечения безопасности;
      </li>
      <li>
        Подтверждения достоверности и полноты персональных данных,
        предоставленных Пользователем;
      </li>
      <li>
        Создания учетной записи для использования Сервиса, если Пользователь дал
        согласие на создание учетной записи;
      </li>
      <li>Обработки платежей пользователя;</li>
      <li>
        Предоставления Пользователю эффективной клиентской и технической
        поддержки при возникновении проблем связанных с использованием Сервиса;
      </li>
      <li>
        Предоставления Пользователю с его согласия, обновлений продукции,
        специальных предложений, информации о ценах, новостной рассылки и иных
        сведений от имени Сервиса или от имени партнеров Сервиса;
      </li>
      <li>
        Синхронизации проектов между приложением “Город.Эксперт” на Windows,
        MacOS, Linux, iOS и Android;
      </li>
      <li>Осуществления рекламной деятельности с согласия Пользователя;</li>
      <li>
        Предоставления доступа Пользователю на сайты или сервисы партнеров
        Сервиса с целью получения продуктов, обновлений и услуг;
      </li>
      <li>
        Усовершенствования Сервиса: анализ информации об использовании Сервиса в
        целях лучшего понимания сетевого поведения, выявления потенциальных
        перебоев в работе и технических проблем (ухудшение скорости обмена
        данными, недоступность Сервиса и т.д.) для повышения качества
        предоставления Сервиса;
      </li>
      <li>
        Предложения новых возможностей в целях непрерывной оптимизации и
        персонализации Сервиса, а также для отправки индивидуальных сообщений о
        новых предложениях, которые могут быть интересны Пользователю;
      </li>
      <li>
        Предотвращения мошеннических действий: выявления и расследования случаев
        мошенничества, нарушения правил безопасности, потенциально запрещенной
        или незаконной деятельности, защиты товарного знака Сервиса.
      </li>
    </ol>
    <p>
      <strong>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong>
    </p>
    <ol>
      <li>
        Обработка персональных данных Пользователя осуществляется в течении 10
        лет любым законным способом, в том числе в информационных системах
        персональных данных с использованием средств автоматизации или без
        использования таких средств.
      </li>
      <li>
        Пользователь соглашается с тем, что Администрация Сервиса вправе
        передавать персональные данные третьим лицам, в целях выполнения заказа
        Пользователя.
      </li>
      <li>
        Пользователь соглашается с тем, что Администрация Сервиса вправе
        осуществлять трансграничную передачу персональных данных.
      </li>
      <li>
        Персональные данные Пользователя могут быть переданы уполномоченным
        органам государственной власти Российской Федерации только по основаниям
        и в порядке, установленным законодательством Российской Федерации.
      </li>
      <li>
        При утрате или разглашении персональных данных Администрация Сервиса
        информирует Пользователя об утрате или разглашении персональных данных.
      </li>
      <li>
        Администрация Сервиса принимает необходимые организационные и
        технические меры для защиты персональной информации Пользователя от
        неправомерного или случайного доступа, уничтожения, изменения,
        блокирования, копирования, распространения, а также от иных
        неправомерных действий третьих лиц.
      </li>
      <li>
        Администрация Сервиса совместно с Пользователем принимает все
        необходимые меры по предотвращению убытков или иных отрицательных
        последствий, вызванных утратой или разглашением персональных данных
        Пользователя.
      </li>
    </ol>
    <p>
      <strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН</strong>
    </p>
    <ol>
      <li>Пользователь обязан:</li>
      <ol>
        <li>
          Предоставить информацию о персональных данных, необходимую для
          пользования Сервисом;
        </li>
        <li>
          Обновить, дополнить предоставленную информацию о персональных данных в
          случае изменения данной информации.
        </li>
      </ol>
      <li>Администрация Сервиса обязана:</li>
      <li>
        Использовать полученную информацию исключительно для целей, указанных в
        п. 4 настоящей Политики конфиденциальности;
      </li>
      <li>
        Обеспечить хранение конфиденциальной информации в тайне, не разглашать
        без предварительного письменного разрешения Пользователя, а также не
        осуществлять продажу, обмен, опубликование, либо разглашение иными
        возможными способами переданных персональных данных Пользователя, за
        исключением п. 5.4 настоящей Политики Конфиденциальности;
      </li>
      <li>
        Принимать меры предосторожности для защиты конфиденциальности
        персональных данных Пользователя согласно порядку, обычно используемому
        для защиты такого рода информации в существующем деловом обороте.
      </li>
      <li>
        Осуществить блокирование персональных данных, относящихся к
        соответствующему Пользователю, с момента обращения или запроса
        Пользователя или его законного представителя либо уполномоченного органа
        по защите прав субъектов персональных данных на период проверки, в
        случае выявления недостоверных персональных данных или неправомерных
        действий.
      </li>
    </ol>
    <p>
      <strong>7. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
    </p>
    <ol>
      <li>
        Администрация Сервиса, не исполнившая свои обязательства, несёт
        ответственность за убытки, причиненные Пользователю в связи с
        неправомерным использованием персональных данных, в соответствии с
        законодательством Российской Федерации, за исключением случаев,
        предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
        Конфиденциальности.
      </li>
      <li>
        В случае утраты или разглашения Конфиденциальной информации
        Администрация сайта не несёт ответственность, если данная
        конфиденциальная информация:
      </li>
      <ol>
        <li>Стала публичным достоянием до её утраты или разглашения;</li>
        <li>
          Была получена от третьей стороны до момента её получения
          Администрацией Сервиса;
        </li>
        <li>Была разглашена с согласия Пользователя.</li>
      </ol>
    </ol>
    <p>
      <strong>8. РАЗРЕШЕНИЕ СПОРОВ</strong>
    </p>
    <ol>
      <li>
        До обращения в суд с иском по спорам, возникающим из отношений между
        Пользователем Сервиса и Администрацией Сервиса, обязательным является
        предъявление претензии (письменного предложения о добровольном
        урегулировании спора).
      </li>
      <li>
        Получатель претензии в течение 30 календарных дней со дня получения
        претензии, письменно уведомляет заявителя претензии о результатах
        рассмотрения претензии.
      </li>
      <li>
        При недостижении соглашения спор будет передан на рассмотрение в
        судебный орган в соответствии с действующим законодательством Российской
        Федерации.
      </li>
      <li>
        К настоящей Политике конфиденциальности и отношениям между Пользователем
        и Администрацией сайта применяется действующее законодательство
        Российской Федерации.
      </li>
    </ol>
    <p>
      <strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
    </p>
    <ol>
      <li>
        Администрация Сервиса вправе вносить изменения в настоящую Политику
        конфиденциальности без согласия Пользователя.
      </li>
      <li>
        Новая Политика конфиденциальности вступает в силу с момента ее
        размещения на странице Сервиса, если иное не предусмотрено новой
        редакцией Политики конфиденциальности
      </li>
      <li>
        Все предложения или вопросы по настоящей Политике конфиденциальности
        следует сообщать по адресу электронной почты: vadim@sputnik.systems
      </li>
    </ol>
  </DocTemplate>
)

export default AgreementPage
