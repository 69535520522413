import React from 'react'
import Sso from '../bundles/Sso/src';
import * as ReactDOM from 'react-dom'

document.addEventListener('DOMContentLoaded', () => {

    console.log('ReactDOM rendered..')
  ReactDOM.render(
    <Sso />,
    document.getElementById('app')
  )
})