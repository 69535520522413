//@flow
import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, MessagePage } from 'sputnik-ui'

function WelcomePage({ history }) {
  const onClick = () => {
    const getRedirectUri = () => {
      const regExp = /systems\/welcome\?redirect_to=(.*)/
      // const regExp = /.*localhost:3000\/welcome\?redirect_to=(.*)/

      const m = regExp.exec(window.location)
      const redirectUri = m && m[1]
      console.log(redirectUri)
      return redirectUri
    }
    const redirectUri = getRedirectUri()

    if (redirectUri) {
      return (window.location = redirectUri)
    }
    history.push('/login')
  }

  return (
    <MessagePage
      emote="hello"
      title="Добро пожаловать!"
      msg="Пароль для входа был выслан на введеный вами e-mail."
      action={
        <Button
          primary
          rounded
          style={{ marginTop: '1.625rem' }}
          onClick={onClick}
        >
          Войти
        </Button>
      }
    />
  )
}

export default withRouter(WelcomePage)
