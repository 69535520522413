import React, { Suspense } from 'react'
// import ReactDOM from 'react-dom'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import { theme, ThemeProvider } from 'sputnik-ui'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { I18nextProvider } from 'react-i18next'
import {  Loader } from 'sputnik-ui'
// import './index.css'
import i18n from './i18n';

const store = configureStore()


const Root = () => (
  <IntercomProvider appId='cao5rcj3' autoBoot={true}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<Loader />}>
              <App />
            </Suspense>
          </I18nextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </IntercomProvider>

)

registerServiceWorker()

// ReactDOM.render(<Root />, document.getElementById('root'))

export default Root
