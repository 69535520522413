// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background: linear-gradient(-45deg, #7303c0, #fdeff9, #fffcdc, #fdeff9);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;

  @keyframes Gradient {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 50% 50%;
    }
    75% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  } */
  background: radial-gradient(
    at center center,
    rgb(255, 234, 78) 0%,
    rgba(245, 214, 58, 1) 100%
  );
  background: ${p => p.theme.palette.other.green};
`

const Header = styled.header`
  position: static;
  top: 0;
  width: 100%;
  z-index: 999;
`

const Content = styled.section`
  /* padding-top: 1.75rem; */

  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const MainTemplate = ({ children, subheader, ...props }) => {
  return (
    <Wrapper {...props}>
      <Content>{children}</Content>
    </Wrapper>
  )
}

MainTemplate.propTypes = {
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
}

export default MainTemplate
