// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LoginTemplate from '../LoginTemplate'
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { Button } from 'sputnik-ui'
import { Link } from 'react-router-dom'
import faceRec from './face_rec.png'
import message_square from './message_square.svg'
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react';
import { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 3.625rem; */
  height: 100%;
  margin: 0 auto;
  /* background: ${(p) => p.theme.palette.other.green}; */
  width: 100%;
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    color: #fff;
  }
`

const Subtitle = styled(Typography)`
&&{
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    color: #fff;
  }
}
`
const MessageSvg = styled.img`
  margin-right: 10px;
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    filter: brightness(0) invert(1);
  }
`

const Image = styled.img`
  position: absolute;
  height: 210px;
  width: auto;
  top:-194px;
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    display: none;
  }
`
const StyledButton = styled(Button)`
  &&{ 
    box-shadow: none;
    color: #6D6D6D;
    @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    color: #fff;
    }
  }
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 30rem;
  padding: 24px;
  background: ${(p) => p.theme.palette.background.primary};
  
  /* background: #FFFFFF; */
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    /* height: 100%; */
    background: none;
    box-shadow: none;
    border: 0px solid rgba(0, 0, 0, 0.3);
  }
`

const AltLink = styled(Link)`
  border: none;
  background: none;
  color: ${(p) => p.theme.palette.text.secondary};
  margin-top: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: rgba(92, 92, 92, 1);
  }
  text-align: center;
  text-decoration: none;
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    color: #F0F8FF;
    &:hover {
      color: #fff;
   }
  }
`

const BoldLink = styled.a`
  /* font-weight: 500; */
  color: ${(p) => p.theme.palette.text.primary};
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: ${(p) => p.theme.palette.text.primary};
  padding-bottom: 2px;
  /* color: black; */

  &:hover {
    color: ${(p) => p.theme.palette.text.secondary};
  }
  @media screen and (max-width: ${p => p.theme.sizes.phone}) {
    color: #fff;
   text-decoration-color: #fff;
    &:hover{
      color: #fff;
    }
  }
`

const RegisterTemplate = ({
  children,
  isConfirmed,
  handleConfirmChange,
  onSubmit,
  form,
  subtitle,
  withAgreement = true,
  title = 'Спутник.Регистрация',
  actionText = 'Зарегистрироваться',
  altLink = null,
  ...props
}) => {
  const [isApp, setApp] = useState(false)

  const {t} = useTranslation('common')

  const { show, shutdown} = useIntercom()

  const handleIntercomOpen = () => {
    show()
  }

  const webInAppCheck = ()=>{
    if(navigator.userAgent.toString().includes("Version")){
      setApp(navigator.userAgent)
      shutdown()
    }
  }

  useEffect(()=>{
    webInAppCheck()
  }, [])

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <Image
          src={faceRec}
        />
        <Subtitle
          variant="h5"
          style={{ marginBottom: '10px' }}
        >
          {title}
        </Subtitle>

        {subtitle && <Subtitle
          color="textSecondary"
          style={{ marginBottom: '10px', fontSize: 14, fontWeight: 400 }}
        >
          {subtitle}
        </Subtitle>}
        {form}
        {withAgreement && (
          <Typography style={{ fontSize: '1rem', marginTop: '1rem' }}>
            {t('common:agreement.reg')}{' '}
            <BoldLink href="/policy.pdf">
            {t('common:agreement.link')}
            </BoldLink>{' '}
            {t('common:agreement.connect')}{' '}
            <BoldLink href="/license.pdf">{t('common:agreement.licence_link')}</BoldLink>.
          </Typography>
        )}
   
        { !isApp 
           && <StyledButton
            onClick={handleIntercomOpen}
            subtle
            style={{ margin: '0 auto' }}>
            <MessageSvg src={message_square} />
            {t('common:support')}
        </StyledButton>}
        {altLink && <AltLink tabIndex={-1} to={altLink.to} onClick={altLink.action}>
          {altLink.label}
        </AltLink>}
      </Form>
      {children}
    </Wrapper>
  )
}

RegisterTemplate.propTypes = {
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
}

export default RegisterTemplate
