import React from 'react'
import DocTemplate from '../../templates/DocTemplate'

const AgreementPage = () => (
  <DocTemplate>
    <h2>Согласие на обработку персональных данных</h2>
    <p>Настоящим я,</p>
    <p>
      далее – «Субъект Персональных Данных», во исполнение требований
      Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» (с
      изменениями и дополнениями) свободно, своей волей и в своем интересе даю
      свое согласие{' '}
      <strong>Обществу с ограниченной ответственностью “СПУТНИК” </strong>(далее
      - ООО “СПУТНИК”) (ОГРН: 1167746722076, ИНН: 7731323200, КПП: 773101001,
      юридический адрес: г. Москва, тер. Инновационного центра Сколково, ул.
      Луговая, д. 4, строение 8, пом. 3., фактический адрес: Республика
      Татарстан, г. Казань, ул. Алексея Козина, д. 3а - 207) на обработку своих
      персональных данных, указываемых при регистрации в Приложении
      “Город.Эксперт” путем заполнения веб-формы.
    </p>
    <p>
      Под персональными, в том числе биометрическими, данными я понимаю любую
      информацию, относящуюся ко мне как к Субъекту Персональных Данных, в том
      числе мои фамилию, имя, отчество, адрес, фотографии, видеоизображения,
      биометрические параметры лица, а также данные об образовании, профессии,
      контактные данные (телефон, факс, электронная почта, почтовый адрес), иную
      другую информацию. Под обработкой персональных данных я понимаю сбор,
      систематизацию, накопление, уточнение, обновление, изменение,
      использование, распространение, передачу, в том числе трансграничную,
      обезличивание, блокирование, уничтожение, бессрочное хранение), и любые
      другие действия (операции) с персональными данными.
    </p>
    <p>
      Обработка персональных данных Субъекта Персональных Данных осуществляется
      исключительно с целью разработки и предоставления программных продуктов, в
      том числе мобильного приложения, “Город.Эксперт” (далее Приложение).
    </p>
    <p>
      Датой выдачи согласия на обработку персональных данных Субъекта
      Персональных Данных является дата отправки регистрационной веб-формы при
      авторизации в Приложении.
    </p>
    <p>
      Обработка персональных данных Субъекта Персональных Данных может
      осуществляться с помощью средств автоматизации и/или без использования
      средств автоматизации в соответствии с действующим законодательством РФ и
      внутренними положениями ООО “СПУТНИК”.
    </p>
    <p>
      ООО “СПУТНИК” принимает необходимые правовые, организационные и
      технические меры или обеспечивает их принятие для защиты персональных
      данных от неправомерного или случайного доступа к ним, уничтожения,
      изменения, блокирования, копирования, предоставления, распространения
      персональных данных, а также от иных неправомерных действий в отношении
      персональных данных, а также принимает на себя обязательство сохранения
      конфиденциальности персональных данных Субъекта Персональных Данных. ООО
      “СПУТНИК” вправе привлекать для обработки персональных данных Субъекта
      Персональных Данных субподрядчиков, а также вправе передавать персональные
      данные для обработки своим аффилированным лицам, обеспечивая при этом
      принятие такими субподрядчиками и аффилированными лицами соответствующих
      обязательств в части конфиденциальности персональных данных.
    </p>
    <p>Я ознакомлен(а), что:</p>
    <ol>
      <li>
        настоящее согласие на обработку моих персональных данных, указанных при
        авторизации в Приложении, действует в течение 10 (десяти) лет;
      </li>
      <li>
        согласие может быть отозвано мною на основании письменного заявления в
        произвольной форме;
      </li>
      <li>
        предоставление персональных данных третьих лиц без их согласия влечет
        ответственность в соответствии с действующим законодательством
        Российской Федерации.
      </li>
    </ol>
  </DocTemplate>
)

export default AgreementPage
