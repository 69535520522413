import React, { useCallback, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core'
import { Button, useInput } from 'sputnik-ui'

export default function ConfirmPhoneModal({
  open,
  handleClose,
  phone,
  history,
  ...props
}) {
  const { value: confirmationCode, bind } = useInput('')
  const [codeError, setCodeError] = useState('', () => setCodeError(''))

  const confirmPhoneNumber = useCallback((e) => {
    e.preventDefault()
    fetch(`${window.location.origin}/phone_confirmations`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        phone,
        confirmation_code: confirmationCode,
      }),
    })
      .then(r => {
        if (!r.ok) throw r
        return r.json()
      })
      .then(({ redirect_to }) =>
        history.push(`/welcome?redirect_to=${redirect_to || ''}`),
      )
      .catch(err => {
        err.json().then(({ message }) => setCodeError(message))
      })
  }, [phone, confirmationCode, history])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Подтвердите номер телефона</DialogTitle>
      <form onSubmit={confirmPhoneNumber}>
      <DialogContent>
        <TextField autoFocus fullWidth label="Код из смс" {...bind}></TextField>
        {codeError && (
          <Typography color="error" style={{ marginTop: '1rem' }}>
            {codeError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" rounded type="submit">
          подтвердить
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  )
}
